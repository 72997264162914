<template>
  <div class="min-h-screen py-12 bg-hp-gray dark:bg-gray-900 dark:text-white">
    <div class="max-w-5xl px-4 mx-auto">
      <!-- Navigation -->
      <div class="flex justify-between items-center mb-40">
        <router-link to="/">
          <img src="@/assets/logos/Homeplan logo (new modern April 2022 - NO .com).svg" style="height: 2.1rem;" alt="Homeplan logo">
        </router-link>
        
       <router-link to="/" class="py-1.5 px-2.5 bg-blue-900 text-white transition ease-in duration-200 text-center text-sm font-semibold shadow-md focus:outline-none rounded-lg">
          Back to home
        </router-link>
      </div>

      <!-- Page heading -->
      <div class="text-6xl text-center font-bold mb-2">Cookies</div>
      <div class="font-semibold text-center text-gray-500 dark:text-gray-300 text-lg mb-28">Collection & Use of Data collected automatically </div>

      <div class="pb-24">
        <p>Cookies are small files that a site or its service provider transfers to your computer's hard drive through your web browser (if you have permitted) that enables the site's or service provider's systems to recognize your browser and capture and remember certain information. They are widely used in order to make websites work, or work more efficiently, as well as to provide information to the owners of the site. <br />
        <br />
        Cookies are stored on the hard drive of your computer and do not necessarily transmit your Personally Identifiable Information (or &ldquo;PII&rdquo;) to Homeplan. <br />
        <br />
        Cookies are used to help Homeplan understand your preferences based on previous or current Website activity, which enables Homeplan to provide you with improved services. <br />
        <br />
        Cookies are also used for security purposes and to compile aggregate Data about Website traffic and Website interaction so that better Website experiences and tools can be offered in the future. <br />
        <br />
        Homeplan may also use trusted third-party service provider(s) that track this information on Homeplan&rsquo;s behalf. Our service provider(s) will place cookies on the hard drive of your computer and will receive data that we select to educate us notably on: (i) how visitors navigate around our Website, (ii) what products are browsed, and (iii) general transaction information. Our service provider(s) analyses this data and provides us with aggregate reports. The data and analysis provided by our service provider(s) will be used to assist us in better understanding our visitors' interests in our Website and how to better serve those interests. The data collected by our service provider(s) may be linked to and combined with data that we collect about you while you are using the Website. Our service provider(s) is/are contractually restricted from using information they receive from our Website other than to assist us. <br />
        <br />
        You can choose to have your computer warn you (through your browser settings) each time a cookie is being sent, or you can choose to turn off all cookies. If you choose to turn cookies off, some of the features that make your Website experience more efficient may not function properly. <br />
        <br />
        For the purposes of continuous optimization of the Website, Homeplan uses the web analysis service of &ldquo;Google Analytics&rdquo;. Google Analytics is a web analytics service offered by Google Inc., a company of the holding company Alphabet Inc., in the US, that tracks and reports website traffic. The data collected is processed in a non-personally-identifying form (IP Anonymization). Google Inc. discloses this information only to third parties to the extent required by law. Google uses the Data collected to track and monitor the use of the Homeplan&rsquo; s Website. This Data is shared with other Google services. Google may use the collected Data to contextualize and personalize the ads of its own advertising network. <br />
        <br />
        You can opt-out of having made your activity on the Homeplan Website available to Google Analytics by installing the Google Analytics opt-out browser add-on. The add-on prevents the Google Analytics JavaScript (ga.js, analytics.js, and dc.js) from sharing information with Google Analytics about visits activity. <br />
        <br />
        For more information on the privacy practices of Google, please visit the Google Privacy Terms web page: <a href="https://www.google.com/intl/en/policies/privacy/" target="_blank" class="font-semibold text-hp-blue">https://www.google.com/intl/en/policies/privacy/</a>. <br />
        <br />
        Please note that cookie-based opt-outs are not effective on mobile applications. However, on many mobile devices, application users may opt out of certain mobile ads via their device settings. The online advertising industry also provides websites from which you may opt-out of receiving targeted ads from advertisers that participate in self-regulatory programs. You can access these websites, and also learn more about targeted advertising and consumer choice and privacy, at <a class="font-semibold text-hp-blue" href="www.networkadvertising.org/managing/opt_out.asp" target="_blank">www.networkadvertising.org/managing/opt_out.asp</a>. <br />
        <br />
        We want to ensure that you, as our valued customer, are fully aware of the use of cookies on our website, and we are proud of our reputation as a transparent and honest service provider that you can trust. <br />
        <br />
        Cookies we use: <br />
        <br />

        <div class="grid grid-cols-4 gap-4">
          <div class="font-semibold">
            Class
          </div>
          <div class="col-span-3 font-semibold">
            Purpose of the Cookies
          </div>

          <div class="font-medium">
            Necessary Cookies 
          </div>
          <div class="col-span-3">
            These cookies are necessary to maintain our services. They are usually only set in response to actions made by you, such as creating an account or saving a wish-list for later. You can set your browser to block or alert you about these cookies, but that can make some parts of the site not work. These cookies do not store any personally identifiable information. 
          </div>

          <div class="font-medium">
            Performance Cookies 
          </div>
          <div class="col-span-3">
            These cookies allow us to count visits and traffic so we can collect insights like which pages are the most popular and see how visitors move around the site. All information these cookies collect is aggregated and therefore, anonymous. 
          </div>

          <div class="font-medium">
            Functional Cookies 
          </div>
          <div class="col-span-3">
            These cookies enable the website to provide enhanced functionality and personalization. They may be set by us or by third party providers whose services we have added to our pages. 
          </div>

          <div class="font-medium">
            Marketing Cookies 
          </div>
          <div class="col-span-3">
            These cookies may be set through our site by our advertising partners to build a profile of your interests and show you relevant adverts on other sites. They do not store directly personal information but can identify your browser and internet device. 
          </div>
        </div>

      </div>
      

      <Footer />
    </div>
  </div>
</template>

<script>
import Footer from "@/components/footer.vue";

export default {
  components: {
    Footer
  },
}
</script>